<template>
  <v-container class="mt-10">
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" tile>
          <v-row>
            <v-col cols="12" md="4">
              <v-card elevation="0" tile>
                <v-card-title class="overline">
                  {{ currPeriod.code || "" }} Appraisal Review
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col
                      class="d-flex flex-row justify-space-around align-items-center overline"
                    >
                      <v-progress-circular
                        :rotate="-90"
                        :size="100"
                        :width="5"
                        :value="
                          (reviews.filter((r) =>
                            ['Reviewed', 'Closed'].includes(r.documentStatus)
                          ).length *
                            100) /
                            reviews.length || 0
                        "
                        color="primary"
                      >
                        {{
                          (reviews.filter((r) =>
                            ["Reviewed", "Closed"].includes(r.documentStatus)
                          ).length *
                            100) /
                            reviews.length || 0
                        }}
                        %
                      </v-progress-circular>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-sheet
                    class="overline px-3 d-flex flex-column text-center align-items-center"
                  >
                    <h4 class="subtitle-2 font-weight-bold primary--text">
                      {{ reviews.length }}
                    </h4>
                    <h6 class="caption">TOTAL</h6>
                  </v-sheet>
                  <v-spacer />
                  <v-sheet
                    class="overline px-3 d-flex flex-column text-center align-items-center"
                  >
                    <h4 class="subtitle-2 font-weight-bold green--text">
                      {{
                        reviews.filter((r) =>
                          ["Reviewed", "Closed"].includes(r.documentStatus)
                        ).length
                      }}
                    </h4>
                    <h6 class="caption">APPROVED</h6>
                  </v-sheet>
                  <v-spacer />
                  <v-sheet
                    class="overline px-3 d-flex flex-column text-center align-items-center"
                  >
                    <h4 class="subtitle-2 font-weight-bold orange--text">
                      {{
                        reviews.filter((r) =>
                          ["Submitted"].includes(r.documentStatus)
                        ).length
                      }}
                    </h4>
                    <h6 class="caption">PENDING</h6>
                  </v-sheet>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
              <v-divider />
            </v-col>
            <v-divider v-else vertical />

            <v-col cols="12" md="8">
              <v-card elevation="0" tile>
                <v-card-title class="overline">
                  My Reviewees

                  <v-spacer />

                  <v-chip x-small dark color="green">
                    {{ stage ? stage.description : "" }}
                  </v-chip>
                </v-card-title>

                <v-card-text style="height: 190px; overflow-y: scroll">
                  <v-row dense>
                    <v-col cols="4" v-for="(reviewee, i) in reviews" :key="i">
                      <v-sheet
                        v-ripple
                        color="grey lighten-4"
                        width="100%"
                        rounded
                        class="pa-3 d-flex flex-row align-items-center justify-space-between"
                      >
                        <v-avatar>
                          <img src="/user.png" :alt="reviewee.employeeName" />
                        </v-avatar>

                        <div>
                          <h4 class="subtitle-1 font-weight-bold primary--text">
                            {{ reviewee.employeeName }}
                          </h4>
                          <h6 class="caption">
                            {{ reviewee.employeeJobTitle }}
                          </h6>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-card tile color="transparent" elevation="0">
          <v-card-title class="overline">
            Pending Reviews <v-spacer />
            <span class="deep-orange--text">{{ reviews.length }}</span>
          </v-card-title>

          <div v-for="(reviewee, i) in reviews" :key="i">
            <v-sheet
              v-if="$vuetify.breakpoint.mdAndUp"
              tile
              class="mb-2 py-3 px-3 d-flex flex-row justify-space-between align-center"
            >
              <v-avatar>
                <img src="/user.png" :alt="reviewee.employeeName" />
              </v-avatar>

              <span class="subtitle-1 font-weight-bolder">
                {{ reviewee.employeeName }}
              </span>

              <v-sheet class="px-3 d-flex flex-column align-items-center">
                <h4 class="subtitle-2 font-weight-light">
                  {{ reviewee.employeeJobTitle }}
                </h4>
              </v-sheet>

              <v-sheet
                class="px-3 d-flex flex-column align-items-center text-center"
              >
                <h4 class="subtitle-2 font-weight-black">
                  {{ reviewee.overallScore }}
                </h4>
                <h6 class="caption">Self Score</h6>
              </v-sheet>

              <v-sheet
                class="px-3 d-flex flex-column align-items-center text-center"
              >
                <h4 class="subtitle-2 font-weight-black">
                  {{ reviewee.overallScore }}
                </h4>
                <h6 class="caption">Supervisor Score</h6>
              </v-sheet>

              <v-sheet class="px-3 d-flex flex-column align-items-center">
                <h6>Comments</h6>
                <p class="caption">
                  {{ reviewee.perfomanceRecommendations }} <a>... more</a>
                </p>
              </v-sheet>

              <v-btn small title="Versions" text color="grey" class="pa-0">
                0
              </v-btn>

              <v-btn
                :to="{
                  name: 'Appraisal Card',
                  params: { code: reviewee.code },
                }"
                text
                tile
                color="blue"
              >
                Review
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-sheet>

            <v-card v-else class="mb-3">
              <v-card-title class="overline">
                {{ reviewee.employeeName }}
                <v-spacer />
                {{ reviewee.employeeJobTitle }}
                <v-spacer />
              </v-card-title>

              <v-divider />

              <v-card-text>
                <v-row>
                  <v-col
                    class="d-flex flex-column justify-center text-center overline"
                  >
                    <h4 class="subtitle-2">My Score</h4>
                    <h1 class="text-h6">
                      {{ reviewee.overallScore }}
                    </h1>
                  </v-col>
                  <v-divider vertical />
                  <v-col
                    class="d-flex flex-column justify-center text-center overline"
                  >
                    <h4 class="subtitle-2">Supervisor Score</h4>
                    <h1 class="text-h6">
                      {{ reviewee.overallScore }}
                    </h1>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider />

              <v-card-text>
                <h6 class="subtitle-2">Comments</h6>
                {{ reviewee.perfomanceRecommendations }}
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-btn text color="info" tile small>
                  <v-icon left>mdi-clipboard-list</v-icon>
                  0 versions
                </v-btn>

                <v-spacer />

                <v-btn
                  :to="{
                    name: 'Appraisal Card',
                    params: { code: reviewee.code },
                  }"
                  small
                  text
                  tile
                >
                  Review
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";

export default {
  name: "AppraisalReview",
  mixins: [RouterMixin],

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getPeriods");
      v.$store.dispatch("appraisal/getReviewees");
      v.$store.commit("appraisal/SET_REVIEW", true);
    });
  },

  computed: {
    reviews() {
      return this.$store.getters["appraisal/reviews"];
    },

    periods() {
      return this.$store.getters["appraisal/periods"];
    },

    currPeriod() {
      const period = this.periods.filter((p) => p.current).shift();
      if (period) {
        this.$store.dispatch("appraisal/getIsReviewer", period.code);
      }
      return period;
    },

    stage() {
      return this.currPeriod.periodStages.filter((s) => s.current).shift();
    },
  },
};
</script>

<style scoped></style>
